
export default {
   select_language: 'ជ្រើសរើសភាសា',
   login_title: 'ចូលគណនី',
   login_button: 'ចូលគណនី',
   username: 'ឈ្មោះ​អ្នក​ប្រើ',
   password: 'ពាក្យសម្ងាត់',
   login_notes: 'សូមស្វាគមន៍មកកាន់ ',
   set_password: 'កំណត់ពាក្យសម្ងាត់',
   profile: 'ប្រវត្តិរូប',
   signout: 'ចាកចេញ',
   administration: 'Administration',
   dark_mode: 'ផ្ទៃពេលយប់',
   light_mode: 'ផ្ទៃពេលថ្ងៃ',
   pages: 'ទំព័រ',
   workflows: 'ដំណើរការការងារ',
   security: 'សុវត្តិភាព',
   users: 'អ្នកប្រើប្រាស់',
   roles: 'តួនាទី',
   phone_number: 'លេខទូរស័ព្ទ',
   filter: 'តម្រង',
   create: 'បង្កើត',
   edit: 'កែសម្រួល',
   close: 'បិទ',
   save: 'រក្សាទុក',
   update: 'ធ្វើបច្ចុប្បន្នភាព',
   total_records: 'ទិន្នន័យសរុប',
   type_to_search: 'វាយបញ្ចូលដើម្បីស្វែងរក',
   copyright_notes: 'រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង',
   download: 'ទាញយក',
   supported_file_types: 'ប្រភេទឯកសារដែលគាំទ្រ',
   max_file_size: 'ទំហំឯកសារអតិបរមា',
   drop_zone_intro_message: 'សូមអូសឯកសារនៅទីនេះ ឬចុចដើម្បីរកមើលឯកសារ',
   full_name: 'ឈ្មោះ​ពេញ',
   email: 'អ៊ីមែល',
   phone: 'លេខទូរស័ព្ទ',
   role_name: 'ឈ្មោះតួនាទី',
   description: 'ការពិពណ៌នា',
   total_users: 'អ្នកប្រើប្រាស់សរុប',
   company_name: 'ឈ្មោះ​ក្រុម​ហ៊ុន',
   name: 'ឈ្មោះ',
   permissions: 'ការអនុញ្ញាត',
   total: 'សរុប',
   field_required: 'ទីតាំងត្រូវបំពេញ',
   min_length: 'តួអក្សរអប្បបរមា',
   max_length: 'តួអក្សរអតិបរមា',
   invalid_format: 'ទម្រង់មិនត្រឹមត្រូវ',
   item_name: 'ឈ្មោះវត្ថុ',
   list: 'បញ្ជី',
   view: 'មើល',
   delete: 'លុប',
   post: 'ប្រកាស',
   import: 'ទាញទិន្នន័យចូល',
   export: 'ទាញទិន្នន័យចេញ',
   login_type: 'ប្រភេទ login',
   reset_password: 'កំណត់ពាក្យសម្ងាត់ឡើងវិញ',
   confirm_delete: 'តើអ្នកប្រាកដក្នុងការលុបទេ?',
   confirm_release_app: 'តើអ្នកប្រាកដក្នុងការបញ្ចេញកម្មវិធីមែនទេ?',
   yes: 'បាទ/ចាស',
   no: 'ទេ',
   sucessfully_saved: 'បានរក្សាទុកដោយជោគជ័យ',
   sucessfully_deleted: 'បានលុបដោយជោគជ័យ',
   sucessfully_released: 'បានបញ្ចេញដោយជោគជ័យ',
   confirm_delete_record: 'តើអ្នកប្រាកដក្នុងការលុបកំណត់ត្រានេះទេ?',
   confirm_post_record: 'តើអ្នកប្រាកដក្នុងការប្រកាសកំណត់ត្រានេះទេ?',
   work_request_submit_success:
      'សំណើការងាររបស់អ្នកលេខ{number}ត្រូវបានបញ្ជូនដោយជោគជ័យ។',
   show: 'បង្ហាញ',
   page_error_title: 'មានបញ្ហាកើតឡើង',
   error_code: 'បញ្ហាកូដ',
   go_back_home: 'ត្រឡប់ទៅទំព័រដើម',
   confirm_password: 'បញ្ជាក់ពាក្យសម្ងាត់',
   id: 'លេខសម្គាល់',
   code: 'កូដ',
   
   registered_date: 'កាលបរិច្ឆេទចុះឈ្មោះ',
   url: 'URL',
   disabled: 'ផ្អាកតំណេីការ',
   login_email: 'អ៊ីមែលប្រេីកត់ត្រាចូល',
   parties: 'ភាគី',
   party_name: 'ឈ្មោះភាគី',
   min_value: 'តម្លៃអប្បបរមា',

   // General
   _blank: ' ',
   select_item_records: 'Select Item Records',
   select: 'ជ្រេីសរេីស',
   required: 'ត្រូវការទិន្នន័យ',
   total_fields: 'ចំនួន fields',
   register: 'ចុះឈ្មោះ',
   member_information: 'Member Information',
   delete_all_risks: 'Delete All Risks',
   warning: 'Warning',
   confirm_delete_all_risks:
      'Are you sure to <span className="text-danger"> Permanently Delete All </span> Risks ?',
   post: 'Post',
   dataadmin: 'Data Admin',
   submit: 'ដាក់សំណេី',
   work_request_submit_success:
      'សំណេីការងាររបស់អ្នកលេខ {number} បានដាក់ស្នេីដោយជោគជ័យ',
   show_only_description_in_membership: 'Show only description in membership',
   add_item: 'Add Item',
   page_error_title: 'An error occurred',
   error_code: 'Error code',
   error_message: 'Error Message',
   url: 'URL',
   enable_row_permission: 'Enable row permission',
   data_access_parties: 'Only these teams can access this data:',
   parties: 'Parties',
   party_name: 'Party Name',
   min_length: 'Mininum characters',
   max_length: 'Maximum characters',
   invalid_format: 'Invalid format',
   documents: '',
   logo: 'Logo',
   fiscalyear: 'ឆ្នាំសារពើពន្ធ',
   holiday: 'ថ្ងៃឈប់សម្រាក',
   holidays: 'ថ្ងៃឈប់សម្រាក',
   areas: 'តំបន់',
   area: 'តំបន់',
   work_request_items: 'ធាតុសំណើការងារ',
   lov: 'តារាងទិន្នន័យសម្រាប់ជ្រើសរើស',
   list_of_values: 'តារាងទិន្នន័យសម្រាប់ជ្រើសរើស',
   

   // Dashboard
   document_library: 'Document Library',
   reg_clinic_claim: 'Register Claim for Clinic',
   top_product_by_class: 'តួលេខផលិតផលតាមចំណាត់ថ្នាក់',
   weekly_premiums: 'ភាគលាភប្រចាំសប្តាហ៍',
   weekly_claims: 'ការទាមទារសំណងប្រចាំសប្តាហ៍',
   create_work_req: 'ដាក់សំណើការងារ',

   //Accounting

   date: 'ការបរិច្ឆេទ',
   next_date: 'Next Date',
   grand_total: 'Grand Total',
   sub_total: 'Sub Total',
   ship_to_address: 'Ship to address',
   billing_address: 'Billing Address',
   address_line_1: 'Address Line 1',
   address_line_2: 'Address Line 2',
   tax: 'Tax',
   total: 'Total',
   exchange_rate: 'Exchange Rate',
   discount_in_percent: 'Discount %',
   discount: 'Discount',
   gross: 'Gross',
   price: 'Price',
   as_of_date: 'As of Date',
   quantity: 'Quantity',
   pay_amount: 'Pay Amount',
   min_premium: 'Min. Premium',
   comission_percent: 'កំរៃជើងសារ %',
   premium: 'Premium',
   recalculate_premium: 'Recalculate Premium',
   amount: 'Amount',
   doc_amount: 'Doc Amount',
   due_amount: 'Due Amount',
   receipt_from: 'Receipt From',
   payment_method: 'Payment Method',
   ref_number: 'Reference Number',
   reference: 'Reference',
   payment_number: 'Payment Number',
   pay_method: 'Pay Method',
   currency: 'Currency',
   invoice_number: 'Invoice Number',
   total_paid: 'Total Paid',
   total_pay_amount: 'Total Pay Amount',
   receipt_number: 'Receipt Number',
   receipt_amount: 'Receipt Amount',
   ship_date: 'Ship Date',
   due_date: 'Due Date',
   location: 'Location',
   partner: 'Partner',
   ship_via: 'Ship via',
   tracking_number: 'Tracking Number',
   custom_condition_note: 'Custom Condition Note',
   transaction_amount: 'Transaction Amount',
   doc_date: 'Doc Date',
   in_due: 'In Due',
   less_than_30days: '<30 Days',
   less_than_60days: '<60 Days',
   less_than_90days: '90 Days',
   over_90days: 'Over 90 Days',
   create_reciept: 'Create Receipt',
   revert_approve: 'Revert Approval',
   print_invoice: 'Print Invoice',
   print_receipt: 'Print Receipt',
   print: 'Print ',
   receipt_viewer: 'Receipt Viewer',
   invoice_viewer: 'Invoice Viewer',
   payment_amount: 'Payment Amount',
   print_bill: 'Print Bill',
   bill_number: 'Bill Number',
   create_payment: 'Create Payment',
   bill_viewer: 'Bill Viewer',
   grand_total_base: 'Grand Total (Base)',

   //Underwrite
   create_risk: 'Create Risk',
   create_risk_master: 'Create Risk Master',
   create_smi_master: 'Create Smi Master',
   create_cover_master: 'Create Cover Master',
   family_code: 'Family Code',
   family_limit: 'Family Limit',
   risk_limit: 'Risk Limit',
   smi_limit: 'SMI Limit',
   policy_management: 'គ្រប់គ្រងកិច្ចសន្យាធានា',
   policy_number: 'លេខកិច្ចសន្យាធានា',
   renewal_processing: 'តំណើការរកកិច្ចសន្យាផុតកំណត់',
   pay_date: 'Pay Date',
   pay_to: 'Pay To',
   commission: 'កំរៃជើងសារ',
   custom_condition: 'Custom Condition',
   family_head: 'Family Head',
   issue_date: 'Issue Date',
   member_search: 'ស្វែងរកអ្នកធានា',
   my_profile: 'My Profile',
   expire_date: 'Expire Date',
   sum_insured: 'Sum Insured',
   member_name: 'Member Name',
   min_sum_insured: 'Min Sum Insured',
   max_sum_insured: 'Max Sum Insured',
   fixed_sum_insured: 'Fixed Sum Insured',
   min_amount: 'Min Amount',
   risk_info: 'Risk Info',
   certificate_viewer: 'Certificate Viewer',
   policy_schedule_viewer: 'Policy Schedule Viewer',
   policy_fleet_viewer: 'Policy Fleet Viewer',
   quote_schedule_viewer: 'Quotation Schedule Viewer',
   quote_fleet_viewer: 'Quotation Fleet Viewer',
   risk_information: 'Risk Information',
   smi_and_covers: 'Smi and Covers',
   risk_description: 'Risk Description',
   risk_to_delete: 'Risk to delete',
   risk_to_add: 'Risk to add',
   smi_cover: 'Smi-Cover',
   risk_code: 'Risk Code',
   producer: 'Producer',
   renewal: 'Renewal',
   referenced_quotation: 'Referenced Quotation',
   valid_days: 'Valid days',
   policy_limit: 'Policy Limit',
   policy_limit_description: 'Policy Limit Description',
   version: 'Version',
   unit: 'Unit',
   quote_number: 'Quote Number',
   sample_risk: 'Sample Risk',
   excel_file: 'Excel file',
   edit_risk: 'Edit Risk',
   add_risk: 'Add Risk',
   original_risk: 'Original Risk',
   per_time: 'Per Time',
   per_day: 'Per Day',
   per_claim: 'Per Claim',
   max_limit: 'Max Limit',

   re_calculate_commission: 'គណនាកំរៃជើងសារឡើងវិញ',
   upload_risk: 'Upload Risk',
   upload_risks: 'Upload Risks',
   create_invoice: 'Create Invoice',
   print_certificate: 'Print Certificate',
   print_fleeting: 'Print Fleeting',
   print_quotation_schedule: 'Print Quotation Schedule',
   create_new_version: 'Create New Version',
   convert_to_policy: 'Convert To Policy',
   upload_policies: 'Upload Policies',
   discounts_loadings: 'Discounts/Loadings',
   discussion: 'Discussion',
   territory_limit: 'Territory Limit',
   subjectivity: 'Subjectivity',
   policy_wording: 'Policy Wording',
   ncd: 'NCD',
   discount_w_example: 'Discount (i.e. 5% or 50)',
   commune_sangkat: 'Commune/Sangkat',
   district_city: 'District/City',
   district: 'District',
   commune: 'Commune',
   province: 'Province',
   risk_premium: 'Risk Premium',
   risk_discount: 'Risk Discount',
   policies_admin: 'Policies Admin',
   view_quote: 'View Quote',
   view_policy: 'View Policy',
   year: 'Year',
   registration_number: 'Registration .No',
   endt_type: 'Endorsement Type',
   endorsements: 'Endorsements',
   endorsement: 'Endorsement',
   endorsement_number: 'Endorsement Number',
   total_limit: 'Total Limit',
   create_endorsement: 'Create Endorsement',
   endorsement_type: 'Endorsement Type',
   print_endorsement: 'Print Endorsement',
   view_endorsement: 'View Endorsement',
   print_quote: 'Print Quote',
   print_policy: 'Print Policy',
   print_fleeting_list: 'Print Fleeting List',
   print_quote_schedule: 'Print Quotation Schedule',
   print_policy_schedule: 'Print Policy Schedule',
   print_operative_endorsement: 'Print Operative Endorsement',
   member_logins: 'Member Logins',
   generate_logins: 'Generate Logins',
   premium_calc_no_risk_info: 'Risk information not found.',
   // Claim

   claim_number: 'Claim Number',
   claim_date: 'Claim Date',
   report: 'Report',
   accident_date: 'Accident Date',
   ref_claim_number: 'Ref Claim Number',
   estimate_amount: 'Estimate Amount',
   estimated: 'Estimated',
   paid: 'Paid',
   accumulated_amount: 'Accumulated Amount',
   create_new_item: 'Create New Item',
   outstanding: 'Outstanding',
   pending_estimated_only: 'Pending Estimated Only',
   pay_to_claims: 'Pay To Claims',
   claim_line: 'Claim Line',
   claim_details: 'Claim Details',
   pay_to_account: 'Pay to Account',
   paneled_clinic: 'Paneled Clinic',
   pay_deductible_now: 'Pay Deductible Now',
   view_report: 'មើលរបាយការណ៍',
   pending_approval_only: 'Pending Approval Only',

   //Setup

   id: 'លេខសំគាល់',
   number: 'លេខ',
   code: 'លេខកូដ',
   level: 'កំរិត',
   phone_number: 'Phone Number',
   first_name: 'First Name',
   last_name: 'Last Name',
   full_name: 'Full name',
   name_kh: 'Name Kh',
   type: 'Type',
   phone1: 'Phone 1',
   class_name: 'Class Name',
   risk_type: 'Risk Type',
   class_code: 'Class Code',
   phone2: 'Phone 2',
   gender: 'Gender',
   is_corporate: 'Is corporate',
   address_line1: 'Address Line 1',
   address_line2: 'Address Line 2',
   id_card_number: 'ID Card Number',
   date_of_birth: 'Date of Birth',
   job_title: 'Job Title',
   email: 'Email',
   phone: 'Phone',
   name: 'ឈ្មោះ',
   local_name: 'ឈ្មោះជាអក្សរខ្មែរ',
   postal_code: 'Postal Code',
   parent: 'Parent',
   head: 'Head',
   make: 'Make',
   model_name: 'Model Name',
   employee: 'Employee',
   EMPLOYEE: 'Employee name',
   BROKER: 'Broker name',
   AGENT: 'Agent name',
   CUSTOMER: 'Customer name',
   VENDOR: 'Vendor name',
   REINSURER: 'ReInsurer name',
   BANK: 'Bank name',
   CLINIC: 'Clinic name',
   GARAGE: 'Garage name',
   broker: 'Broker',
   insurer: 'Insurer',
   list_type: 'List Type',
   bank: 'Bank',
   beneficiary_bank: 'Beneficiary Bank',
   item_code: 'លេខកូដមុខទំនិ្',
   short_description: 'Short Description',
   customer: 'អតិថិជន',
   customer_type: 'Customer Type',
   customer_notes: 'Customer Notes',
   agent: 'Agent',
   charge: 'Charge',
   charges: 'Charges',
   apply: 'Apply',
   smis_and_covers: 'SMIs and Covers',
   param_type: 'Param Type',
   is_list: 'Is List',
   values: 'Values',
   parameters: 'Parameters',
   itemname: 'Item Name',
   groups: 'Groups',
   group_name: 'Group Name',
   premium_rate: 'Premium Rate (i.e 15 or 15%)',
   group: 'Group',
   description: 'បរិយាយ',
   long_description: 'បរិយាយវែង',
   company_name: 'Company name',
   contact_name: 'Contact Name',
   contact_email: 'Contact Email',
   contact_phone1: 'Contact Phone 1',
   contact_phone2: 'Contact Phone 2',
   beneficiary_co_name: 'Beneficiary Co-Name',
   permissions: 'Permissions',
   item_name: 'ឈ្មោះមុខទំនិញ',
   
   list: 'តារាង',
   cover: 'Cover',
   department: 'Department',
   approve: 'អនុម័ត',
   approve_all: 'អនុម័តទាំងអស់',
   total_approved_amount: 'Total Approved Amount',
   approved_amount: 'Approved Amount',
   approved_reason: 'Approved Reason',
   revertapprove: 'Revert Approve',
   claim: 'Claim',
   min_year: 'Min. Year',
   max_year: 'Max. Year',
   min_cc: 'Min. CC',
   max_cc: 'Max. CC',
   employee_commissions: 'កំរៃជើងសាររបស់បុគ្គលិក',
   min_si: 'Min. SI',
   max_si: 'Max. SI',
   make_model: 'Make & Model',
   creditterm: 'Credit Term',
   claimpayment: 'Claim Payment',
   invoice: 'Invoice',
   listofvalue: 'List of Value',
   motormodel: 'Motor Model',
   orgunit: 'Org Unit',
   policy: 'Policy',
   productclass: 'ថ្នាក់ផលិតផល',
   role: 'Role',
   receipt: 'Receipt',
   all: 'All',
   user: 'User',
   quote: 'Quote',
   reinsurer: 'Re Insurer',
   policypushsms: 'Policy Push SMS',
   workrequest: 'Work Request',
   tariff: 'ការដាក់តម្លៃ',
   workrequestitem: 'Work Request Item',
   sysjob: 'System Job',
   sysmsg: 'System Message',
   setting: 'Setting',
   autonum: 'លេខរៀងទិន្នន័យ',
   selfclaim: 'Self Claim',
   xrate: 'Exchange Rate',
   policycancelrequest: 'Policy Cancel Request',
   payment: 'Payment',
   apikey: 'API Key',
   bill: 'Bill',
   clinic: 'Clinic',
   vendor: 'Vendor',
   msgtpl: 'ទម្រង់សារ',
   ar_query: 'Account Receivable',
   ap_query: 'Account Payable',
   garage: 'Garage',
   claim_category: 'ប្រភេទមុខទំនិញ',
   claim_categories: 'ប្រភេទមុខទំនិញ',
   category: 'ប្រភេទមុខទំនិញ',
   category_mapping: 'Category Mapping',
   map_to_category: 'Map to category',
   upload: 'Upload',
   upload_now: 'Upload now',
   message_upload_category:
      'This will upload all categories in your Excel file.',
   message_upload_claim_item:
      'This will upload all claim items in your Excel file.',
   message_upload_clinic_item:
      'This will upload all clinic items in your Excel file.',
   message_upload_risk: 'This will upload all risks in your Excel file.',
   message_upload_cover: 'This will upload all covers in your Excel file.',
   message_upload_smi: 'This will upload all smis in your Excel file.',
   message_upload_condition:
      'This will upload all conditions in your Excel file.',
   message_upload_motor_model:
      'This will upload all motor models in your Excel file.',
   download_category_template: 'Download Category Template',
   download_cover_template: 'Download Cover Template',
   download_person_risk_template: 'Download Risk Template',
   download_condition_template: 'Download Condition Template',
   download_smi_template: 'Download SMI Template',
   download_claim_item_template: 'Download Claim Item Template',
   download_clinic_item_template: 'Download Clinic Item Template',
   download_motor_model_template: 'Download Motor Model Template',
   claim_amount: 'Claim Amount',
   default_cost: 'ថ្លៃដើមdefault',
   deductible: 'Deductible',
   deductibles: 'Deductibles',
   show_in_online_purchase: 'Show in online purchase?',
   item: 'Item',
   expected_date: 'Expected Date',
   product: 'ផលិតផល',
   in_percent: 'in Percent?',
   product_code: 'កូដផលិតផល',
   product_name: 'ឈ្មោះផលិតផល',
   period_type: 'Period Type',
   class: 'Class',
   effective_date: 'Effective Date',
   notes: 'Notes',
   '': '',
   rate: 'Rate',
   exchange_rates_table: 'Exchange Rates Table',
   address_and_contacts: 'Address and Contacts',
   deductible_in_percent: 'Deductible (i.e. 5% or 50)',
   deductible_description: 'Deductible Description',
   applied_deductibles: 'Will be applied with these deductibles',
   deductible_line: 'Deductible Line',
   product_smi_cover: 'Product-SMI-Cover',
   default: 'Default',
   due_days: 'Due Days',
   comission: 'Comission',
   commissions: 'កំរៃជើងសារ',
   currency_name: 'Currency Name',
   base_currency: 'Base Currency',
   credit_term_name: 'Credit Term Name',
   smi: 'SMI',
   smi_description: 'SMI Description',
   risk: 'Risk',
   condition: 'Condition',
   recipient: 'Recipient',
   charge_code: 'Charge Code',
   charge_name: 'Charge Name',
   claim_admins: 'Claim Admins',
   policy_status: 'Policy Status',
   quotation_status: 'Quotation Status',
   work_request_state: 'Work Request State',
   work_request_priority: 'Work Request Priority',
   claim_status: 'Claim Status',
   clinic_items: 'Clinic Items',
   clinics: 'គ្លីនិក',

   // Work Request

   to_department: 'To Department',
   priority: 'Priority',
   for: 'For',
   on_behalf_of: 'On behalf of',
   request_item: 'Request Items',
   reason: 'Reason',
   status: 'Status',
   remark: 'Remark',
   request_number: 'Request Number',
   request_date: 'Request Date',
   update_status: 'Update Status',
   //Security

   login_title: 'Login',
   login_button: 'Login',
   user_id: 'User ID',
   verify_code: 'Verify Code',
   login_name: 'User name or email or phone number',
   user_name: 'User name',
   password: 'Password',
   login_notes: 'Welcome to ',
   set_password: 'Set password',
   profile: 'Profile',
   signout: 'Signout',
   account: 'Account',
   account_type: 'Account Type',
   type_detail: 'Type Detail',
   confirm_password: 'Confirm Password',
   role_name: 'Role name',
   key: 'Key',

   //System

   run_type: 'Run Type',
   run_time: 'Run Time',
   run_function: 'Run Function',
   enabled: 'Enabled',
   setting_name: 'Setting Name',
   value: 'Value',
   subject: 'Subject',
   body: 'Body',
   body_message: 'Body Message',
   sender: 'Sender',
   date_time: 'Date Time',
   date_type: 'Date Type',
   tries: 'Tries',
   error: 'Error',

   //Report

   statement_of_accounts: 'Statement of Accounts',
   outstanding_reimbursement: 'Outstanding Reimbursement',
   unpaid_claims_clinic: 'Unpaid claims to clinic',
   member: 'Member',
   claim_reports: 'Claim Reports',
   // Menu
   setup: 'រៀបចំទិន្នន័យ',
   currencies: 'រូបិយប័ណ្ណ',
   product_masters: 'ផលិតផល',
   risk_codes: 'លេដកូដហានិភ័យ',
   risk_code: 'លេដកូដហានិភ័យ',
   risks: 'ហានិភ័យ',
   smis: 'ធាតុត្រូវធានា',
   covers: 'Covers',
   conditions: 'លក្ខខណ្ឌធានា',
   product_classes: 'ថ្នាក់ផលិតផល',
   products: 'ផលិតផល',
   claim_items: 'មុខទំនិញ',
   claim_item: 'មុខទំនិញ',
   locations: 'ទីតាំង',
   motors_setup: 'រៀបចំទិន្នន័យយានយន្ត',
   makes: 'ក្រុមហ៊ុនផលិត',
   models: 'ម៉ូដែល',
   special_models: 'ម៉ូដែលពិសេស',
   tariffs: 'ការដាក់តម្លៃ',
   underwriting: 'ផ្នែកកិច្ចសន្យា',
   work_requests: 'សំណើការងារ',
   quotation_management: 'គ្រប់គ្រងសម្រង់តម្លៃ',
   quotations: 'សម្រង់តំលៃ',
   claims: 'ទាមទារសំណង',
   claim_registered: 'ការទាមទារសំណងបានកត់ត្រា',
   partners: 'ដៃគូរជំនួញ',
   policies: 'កិច្ចសន្យាធានា',
   document_library: 'បណ្ណាល័យឯកសារ',
   claims_register: 'ចុះបញ្ជីទាមទារសំណង',
   org_units: 'អង្គការលេខ',
   organisation_units: 'អង្គការលេខ',
   search: 'ស្វែងរក',
   please_select_record: 'សូមជ្រើសរើសរបាយការណ៍',
   claim_reports: 'របាយការណ៍ទាមទារសំណង',
   claim_payments: 'ទាមទារសំណងបានសង',
   payment_requests: 'សំណើសងសំណង',
   claim_payment_requests: 'សំណើសងសំណង',
   notif_msg_templates: 'ទម្រង់សារទាក់ទងសំណង',
   accounting: 'គណនេយ្យ',
   data_setup: 'រៀបចំទិន្នន័យ',
   data_type: 'ប្រភេទទិន្នន័យ',
   items: 'Items',
   item_categories: 'Item Categories',
   credit_terms: 'គោលការណ៍ជំពាក់',
   receivables: 'គណនីត្រូវទារ',
   invoices: 'វិក័យប័ត្រ',
   receipts: 'វិក័យប័ត្រទទួលប្រាក់',
   receivables_query: 'គណនីត្រូវទារ',
   payables: 'Payables',
   bills: 'វិក័យប័ត្រត្រូវបង់ប្រាក់',
   payments: 'បង់ប្រាក់',
   work_request_list: 'Work Request List',
   payables_query: 'គណនីត្រូវសង',
   security: 'ប្រព័ន្ធសុវត្ថិភាព',
   api_keys: 'API Keys',
   data_partitions: 'Data Partitions',
   users: 'អ្នកប្រើប្រាស់',
   roles: 'តួនាទីប្រើប្រាស់',
   system: 'ប្រព័ន្ធ',
   system_jobs: 'ការងារស្វ័យប្រវត្តិរបស់ប្រព័ន្ធ',
   settings: 'ការកំណត់',
   message_templates: 'ទម្រង់សារ',
   messages: 'សារ',
   workflows: 'Workflows',
   limit: 'Limit',
   more_limits: 'More Limits',
   // Header Title
   list_of_value: 'List of Values',
   moto_makes: 'Motor Makes',
   motor_models: 'Motor Models',
   motor_special_models: 'Motor Special Models',
   organisational_units: 'Organisational Units',
   system_settings: 'System Settings',
   my_claims: 'ទាមទារសំណងរបស់ខ្ញុំ',
   my_claim: 'ទាមទារសំណងរបស់ខ្ញុំ',
   my_policies: 'My Policies',
   set_api_key: 'Set API Key Reference Data',
   account_payables: 'គណនីត្រូវសង',
   account_receivables: 'គណនីត្រូវទារ',
   claims_receiver_admin: 'Claims Receiver Admin',
   system_setting: 'កំណត់ប្រព័ន្ធ',
   register_claim: 'បង្តើតការទាមទារសំណង',
   reports: 'របាយការណ៍',
   confirm: 'បញ្ជាក់',
   confirm_update_duplicated_risk: 'បានរកឃើញពត៍មានស្ទួនជាមួយសមាជិកខាងក្រោម: {detail}',

};
