import Cookies from 'js-cookie';
import { formatListMessages, parseJson } from './HtmlHelper';
import { loginPage } from 'constants/defaultValues';
import { setCurrentUser } from './Utils';

const buildJsonOptions = (method, params) => {
   return {
      method: method, // 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', //'same-origin', // include, *same-origin, omit
      crossDomain: true,
      headers:
         params instanceof FormData
            ? {
                 //'Content-Type': 'multipart/form-data',
                 'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
              }
            : {
                 'Content-Type': 'application/json',
                 Accept: 'application/json',
                 'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
              },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body:
         params instanceof FormData
            ? params
            : params
            ? JSON.stringify(params)
            : null, // body data type must match "Content-Type" header
   };
};

let _apiUrl = '';

const setApiUrl = (url) => (_apiUrl = url);

const getApiUrl = () => {
   return _apiUrl ?? '';
};

const mapUrl = (endpoint) => {
   if (endpoint === undefined) return '';
   if (endpoint?.startsWith('http')) return endpoint;
   const apiUrl = _apiUrl;
   const url = apiUrl + (apiUrl.endsWith('/') ? '' : '/') + endpoint;
   return url;
};

const getParamStr = (params) => {
   if (!params) return '';
   const ret = [];
   for (var p in params) {
      ret.push(`${p}=${params[p]}`);
   }
   return ret.join('&');
};

const fetchx = async (
   method,
   endpoint,
   params = null,
   skipRedirectLogin = false
) => {
   const options = buildJsonOptions(method, params);
   try {
      let fullUrl = mapUrl(endpoint);
      if (method == 'GET' && params)
         fullUrl += (fullUrl.includes('?') ? '&' : '?') + getParamStr(params);

      const response = await fetch(fullUrl, options);
      if (response.ok) {
         let data = {};
         try {
            data = await response.json(); // this would raise error i.e. Delete method
         } catch {}
         return { data: data, ok: response.ok, code: response.status };
      }
      const msg = await checkResponseAsync(
         response,
         () => {
            //console.log("Repeating calling " + endpoint)
            return fetchx(method, endpoint, params);
         },
         skipRedirectLogin
      );
      return {
         data: msg == '' ? response.statusText : msg,
         ok: false,
         code: response.status,
      };
   } catch (ex) {
      console.log(ex);
      return errorCatcher(ex);
   }
};

const errorCatcher = (ex) => {
   console.log(ex);
   let errMsg = ex.message;
   if (errMsg == 'Failed to fetch')
      errMsg = `Could not connect to API server. Please try again later.`;
   return {
      data: errMsg,
      ok: false,
      code: 500,
   };
};

const getBlob = async (endpoint) => {
   const options = buildJsonOptions('GET', null);
   try {
      const url = mapUrl(endpoint);
      const response = await fetch(url, options);
      if (response.ok) {
         const data = await response.blob();
         return { data: data, ok: response.ok, code: response.status };
      }
      const msg = await checkResponseAsync(response, () => {
         return getBlob(endpoint);
      });
      return { data: msg, ok: false, code: response.status };
   } catch (ex) {
      return errorCatcher(ex);
   }
};

const get = async (endpoint, params = null) => {
   return fetchx('GET', endpoint, params);
};

const tryGet = async (endpoint, params = null) => {
   return await fetchx('GET', endpoint, params, true);
};

const post = async (endpoint, params) => {
   return fetchx('POST', endpoint, params);
};
const postBlob = async (endpoint, params) => {
   const options = buildJsonOptions('POST', params);
   try {
      const response = await fetch(mapUrl(endpoint), options);
      if (response.ok) {
         const data = await response.blob();
         return { data: data, ok: response.ok, code: response.status };
      }
      const msg = await checkResponseAsync(response, () => {
         return postBlob(endpoint, params);
      });
      return { data: msg, ok: false, code: response.status };
   } catch (ex) {
      return errorCatcher(ex);
   }
};
const put = async (endpoint, params) => {
   return fetchx('PUT', endpoint, params);
};

const postOrPut = async (endpoint, editingId, params) => {
   if (editingId) {
      const url =
         endpoint + (endpoint.endsWith('/') ? editingId : '/' + editingId);
      return put(url, params);
   }
   return post(endpoint, params);
};

const deletex = async (endpoint) => {
   return fetchx('DELETE', endpoint);
};

const deleteWithBody = async (endpoint, params) => {
   return fetchx('DELETE', endpoint, params);
};

let isRefreshing = false;

const checkResponseAsync = async (
   response,
   onAuthRefreshed,
   skipRedirectLogin = false
) => {
   if (isRefreshing) return false;

   if (response.status == 429) {
      return 'Access limit reached. Hold on until next 15 seconds.';
   }
   if (
      //msg.includes('Signature has expired') || msg.includes('Session has expired')
      response.status == 401 &&
      skipRedirectLogin === false
   ) {
      isRefreshing = true;
      const refreshRes = await post('auth/refresh', {});
      if (refreshRes.ok) {
         setCurrentUser(refreshRes.data);
         if (onAuthRefreshed) {
            onAuthRefreshed();
            return '';
         }
      } else {
         if (skipRedirectLogin == false) {
            localStorage.clear();
            localStorage.setItem(
               'SavedUrl',
               JSON.stringify(window.location.pathname)
            );
            window.location.href = loginPage;
         }
      }
   }

   let msg = await response.text();
   try {
      const err = parseJson(msg);
      if (err) msg = stringifyError(err);
   } finally {
      return msg;
   }
};

const stringifyError = (err) => {
   let msg = '';
   if (err?.errors) {
      //&& errJson.type?.includes("https://tools.ietf.org/html/")) {
      msg = formatListMessages(`${err.status}: ${err.title}`, err.errors);
   } else if (err?.message) msg = err.message;
   else msg = JSON.stringify(err);
   return msg;
};

const apix = {
   get,
   post,
   put,
   postOrPut,
   deletex,
   deleteWithBody,
   getBlob,
   postBlob,
   tryGet,
   getApiUrl,
   setApiUrl,
};
export default apix;
