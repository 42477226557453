
export const defaultMenuType = 'menu-sub-hidden';
export const MENU_NO_LANDING_PAGE = 'MENU_NO_LANDING_PAGE';
export const loginPage = "/account/login";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'kh', name: 'ភាសាខ្មែរ', direction: 'ltr' }
];

export const currentUser = {};

export const adminRoot = '/admin';

export const searchPath = `${adminRoot}/pages/miscellaneous/search`;


export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueolympic'; //light.purplemonster
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
